@config "../../tailwind.config.js";
@tailwind base;
//@tailwind components;　// TODO なぜコメントしているのか調べる
@tailwind utilities;

* {
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-size: 62.5%;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  word-break: break-all;
}

html:focus-within {
  scroll-behavior: smooth;
}

html {
  scroll-padding-top: 58px;
  @media (min-width: 940px) {
    scroll-padding-top: 63px;
  }
}

.fontfeaturesettings {
  font-feature-settings: 'palt';
}
.scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar::-webkit-scrollbar {
  display: none;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.img_illust01_top {
  width: 80px;

  @media (min-width: 859px) {
    top: 6rem;
    left: -5rem;
  }

  @media (max-width: 1240px) {
    top: 6rem;
    left: -5rem;
  }

  @media (max-width: 1024px) {
    top: 10%;
    left: 3%;
    width: 9%;
  }
}

.column60.column60_col3 {
  .box:nth-of-type(1n + 2) {
    @media (max-width: 1120px) and (min-width: 1024px) {
      margin-left: 5%;
    }
  }

  .box:nth-of-type(3n + 1) {
    @media (min-width: 1024px) {
      margin-left: 0;
    }
  }

  .box:nth-of-type(1n + 4) {
    @media (min-width: 1024px) {
      margin-top: 2.5rem;
    }
  }
}

.thumbnail {
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    padding-top: calc(278.25 / 450 * 100%);
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: transform 0.5s ease-out;
  }
}

.thumnail_etc {
  &:before {
    content: '';
    display: block;
    padding-top: calc(157.5 / 280 * 100%);
  }
}

.profile {

  @media (max-width: 1120px) and (min-width: 1024px) {
    width: 30%;
  }
}

.thumbnail_hover {
  .lead,
  .title {
    opacity: 1;
    transition: opacity 0.5s ease-out;
  }
}

.thumbnail_hover:hover {
  @media (min-width: 1024px) {
    .lead,
    .title {
      opacity: 0.6;
    }
  }

  img {
    transform: scale(1.2);
  }

  .img_icon {
    transform: none;
  }
}

.tag {
  display: inline-block;
}

.sec_inner_w {
  width: 60rem;

  @media (max-width: 1024px) {
    width: calc(272 / 320 * 100%);
  }
}

.sec_inner_w1120 {
  width: 1120px;

  @media (max-width: 1120px) and (min-width: 1024px) {
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.sec_inner_w60 {
  width: 60rem;

  @media (max-width: 1120px) and (min-width: 1024px) {
    width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

.sec_inner_w620 {
  width: 620px;
  margin-right: 4rem;
  @media (max-width: 1024px) {
    width: 100%;
    margin-right: 0;
  }
  @media (max-width: 1149px) {
  }
}

.sec_detail {
  width: 57.2rem;
  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0;
  }

  @media (max-width: 1650px) and (min-width: 1024px) {
    width: 90%;
    margin: 0 auto;
  }
}

.sec_inner_w293 {
  width: 293px;

  @media (max-width: 1120px) and (min-width: 1024px) {
    width: 30%;
  }
}

.column_w450 {
  width: 450px;

  @media (max-width: 1120px) and (min-width: 1024px) {
    width: 48%;
  }
}

.column_w280 {
  width: 280px;

  @media (max-width: 1120px) and (min-width: 1024px) {
    width: 30%;
  }
}

.detail_col2 {
  .column_w280 {
    @media (min-width: 1024px) {
      width: 45%;
    }
  }

  .column60_col2 .box:nth-of-type(2n + 1) {
    @media (min-width: 1024px) {
      margin-right: 10%;
    }
  }
}

.column_m40 {
  .box:nth-of-type(1n + 2) {
    @media (max-width: 1024px) {
      margin-top: 1rem;
      margin-left: 0;
    }
    margin-left: 4%;

    @media (max-width: 1120px) and (min-width: 1025px) {
      margin-left: 5%;
    }
  }

  .box:nth-of-type(3n + 1) {
    margin-left: 0;
  }

  .box:nth-of-type(1n + 4) {
    margin-top: 4%;

    @media (max-width: 1120px) and (min-width: 1024px) {
      margin-top: 5%;
    }
  }
}

.img_arrow {
  width: 76px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    width: calc(46 / 320 * 100%);
  }
}

.img_title_pickup {
  display: block;
  position: absolute;
  width: 230px;
  height: 230px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;

  @media (max-width: 1024px) {
    width: 135px;
    height: 135px;
    top: 50%;
  }
}

.accordion__button {
  &:hover {
    opacity: 0.8;
  }

  &:before {
    content: none !important;
  }
}

.accordion__item {
  &:nth-of-type(1n + 2) {
    margin-top: 0.5rem;
  }
}

[hidden] {
  display: none;
}
//
.accordion__icon {
  width: 25px;
}

.accordion__quest {
  width: 93%;
}

.img_accordion {
  width: 36px;
}

.fill {
  fill: #35478c;
}

.page_button {
  &:hover {
    .fill {
      fill: #ffffff;
    }
  }
}

.detail_button {
  &:hover {
    .fill {
      fill: #4e7ac7;
    }
  }
}

.fill-primary {
  fill: #35478c;
}

.fill-secondary {
  fill: #4e7ac7;
}

[data-observe] {
  opacity: 0;
  transform: translateY(10%);
}

.c-select_wrap {
  &:before {
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 11px;
    height: 11px;
    content: '';
    border-top: 2px solid #424242;
    border-right: 2px solid #424242;
    transform: translateY(-50%) rotate(135deg);
  }
}

select {
  -webkit-appearance: none; /* ベンダープレフィックス(Google Chrome、Safari用) */
  -moz-appearance: none; /* ベンダープレフィックス(Firefox用) */
  appearance: none; /* 標準のスタイルを無効にする */
  background-color: transparent !important;
  background-image: none;
  outline: none !important;
}

.error_input {
  input {
    background-color: rgba(255, 65, 54, 0.08);
  }
  select {
    background-color: rgba(255, 65, 54, 0.08) !important;
  }
}

.checkbox:checked + .checkbox_input:before {
  position: absolute;
  top: 50%;
  left: 0.1rem;
  display: block;
  width: 0.8rem;
  height: 0.5rem;
  margin-top: -0.4rem;
  content: '';
  border-color: #ffffff;
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(-45deg);
}

.main_hero {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 885px;

  @media (max-width: 1024px) {
    width: 100%;
  }
}

.news_detail {
  font-size: 1rem;
  text-align: left;

  h2 {
    position: relative;
    text-align: left;
    font-size: 1.25rem;
    font-weight: 700;
    margin-top: 2.5rem;
  }

  h3 {
    padding-left: 1rem;
    position: relative;
    text-align: left;
    font-size: 1rem;
    font-weight: 700;
    margin-top: 0.75rem;
    &:before {
      background-color: #000;
      position: absolute;
      top: 50%;
      left: 0;
      width: 10px;
      height: 3px;
      transform: translateY(-50%);
      content: '';
    }
  }

  p {
    text-align: left;
    margin-top: 0.75rem;
  }

  ol,
  ul {
    margin-top: 0.75rem;
    padding-left: 1.5rem;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: inherit;
  }

  li {
    text-align: left;
  }

  .custom-selection {
    text-align: left;
    margin-top: 0.75rem;
  }
}

.news_detail,
.seminar_detail,
.service_detail {
  font-size: 1rem;
  text-align: left;

  figure {
    width: 100%;

    img {
      width: 100%;
    }
  }

  h2 {
    position: relative;
    text-align: left;
    font-size: 1.25rem;
    font-weight: 700;
    margin-top: 2.5rem;
  }

  h3 {
    padding-left: 1rem;
    position: relative;
    text-align: left;
    font-size: 1rem;
    font-weight: 700;
    margin-top: 0.75rem;
    &:before {
      background-color: #000;
      position: absolute;
      top: 50%;
      left: 0;
      width: 10px;
      height: 3px;
      transform: translateY(-50%);
      content: '';
    }
  }

  ol,
  ul {
    margin-top: 0.75rem;
    padding-left: 1.5rem;
  }

  .indexContents {
    div {
      text-align: left;
      font-size: 1rem;
      &:first-child {
        margin-top: 0;
      }
      margin-top: 0.25rem;
    }
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  ul {
    li {
      list-style-type: disc;
    }
  }

  li {
    text-align: left;
  }

  p {
    text-align: left;
    margin-top: 0.75rem;
  }

  a {
    color: #005990;
    &:hover {
      text-decoration: underline;
    }
  }
}

.index_contents_column {
  &.indexContents {
    .index_contents_column_list {
      div {
        text-align: left;
        font-size: 1rem;
        &:first-child {
          margin-top: 0;
        }
        margin-top: 0.25rem;
      }
    }

    a {
      color: #4E7AC7;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.column_detail {
  font-size: 1rem;

  .column_content {
    .button__visible {
      margin: 2.5rem auto 0;
      color: #ffffff;
    }

    figure {
      width: 100%;

      img {
        width: 100%;
      }
    }

    h2 {
      position: relative;
      text-align: left;
      font-size: 1.25rem;
      font-weight: 700;
      padding: 0 0.5rem;
      margin-top: 2.5rem;
      background-color: #F6F4EF;
    }

    h3 {
      padding-left: 1rem;
      position: relative;
      text-align: left;
      font-weight: 700;
      margin-top: 2rem;

      &:before {
        background-color: #F6F4EF;
        position: absolute;
        top: 50%;
        left: 0;
        width: 5px;
        height: 100%;
        transform: translateY(-50%);
      }
    }

    & > ol,
    & > ul {
      margin: 0.75rem 1.5rem;
      padding: 1.5rem;
      border: 1px solid #F6F4EF;
    }

    ol {
      li {
        list-style-type: decimal;
      }
    }

    ul {
      li {
        list-style-type: disc;
      }
    }

    li {
      text-align: left;

      a {
        color: #4E7AC7;
      }

      a:hover {
        text-decoration: underline;
      }
    }

    p {
      margin: 0.75rem 0;
      text-align: left;

      a {
        color: #4E7AC7;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }
}

.seminar_detail,
.service_detail {
  iframe {
    width: 100%;
  }
}

.base_button {
  border-radius: 9999px;
  border: 2px solid #35478c;
  text-align: center;
  display: inline-block;
  font-weight: 700;
}

.linkbutton__small {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.linkbutton__small_login {
  font-size: 0.875rem;
  padding: 0.5rem 1rem 0.5rem 0.5rem;

  @media (max-width: 1149px) {
    font-size: 0.75rem;
  }

  @media (max-width: 1270px) and (min-width: 1024px) {
    font-size: 0.75rem;
  }
}

.linkbutton__medium {
  font-size: 1rem;
  @media (max-width: 1149px) {
    font-size: 0.875rem;
  }
  padding: 0.75rem 1rem;
  @media (max-width: 1024px) {
    width: 83.333333%;
  }
}

.linkbutton__large {
  width: 118px;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.linkbutton__none {
  background-color: #ffffff;
  border: 0;
  color: #424242;
}

.linkbutton__xlarge {
  font-size: 1rem;
  width: 19rem;
  padding: 0.75rem 1rem;

  @media (max-width: 1149px) {
    font-size: 0.875rem;
  }

  @media (max-width: 1024px) {
    width: 75%;
  }
}

.detailbutton {
  font-size: 0.875rem;
  padding: 0.375rem 1rem;
  width: 109px;

  @media (max-width: 1149px) {
    font-size: 0.875rem;
  }
}

.button__visible {
  background-color: #35478c;
  color: #ffffff;
}

.button__hidden {
  color: #35478c;
}

.button__none {
  background-color: #ffffff;
  border: 0;
  color: #424242;
}

.button__small {
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.button__medium {
  font-size: 1rem;
  padding: 0.75rem 1rem;
  width: 15rem;

  @media (max-width: 1024px) {
    width: 100%;
  }
}

.button__large {
  width: 118px;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
}

.button__xlarge {
  font-size: 1rem;
  padding: 0.75rem 1rem;
  width: 19rem;
}

.button__full {
  width: 100%;
}

.guide_box1 {
  .top_content_nth02_inner {
    width: 100%;
    color: #424242;
    text-align: left;

    .title_type01 {
      margin-bottom: 50px;
    }
    .guide_inter {
      section {
        padding-bottom: 24px;
        h2 {
          font-size: 1.5rem;
          font-weight: 700;
          padding-bottom: 16px;

          @media (max-width: 1024px) {
            font-size: 1rem;
          }
        }

        h3 {
          font-size: 1rem;
          display: block;
          line-height: 1.8;
          padding-bottom: 8px;
          position: relative;
          box-sizing: border-box;
        }

        p {
          font-size: 1rem;
        }

        div {
          font-size: 1rem;
        }

        div {
          display: block;
          line-height: 1.8;
          padding-bottom: 8px;
          position: relative;
          padding-left: 16px;
          box-sizing: border-box;
          u {
            display: block;
            line-height: 1.8;
            padding-bottom: 8px;
            position: relative;
            padding-left: 20px;
            box-sizing: border-box;
            text-decoration: none;
            &::before {
              position: absolute;
              top: 0;
              left: 0;
              display: inline-block;
              content: '';
            }
            &:nth-of-type(1) {
              &::before {
                content: '①';
              }
            }
            &:nth-of-type(2) {
              &::before {
                content: '②';
              }
            }
            &:nth-of-type(3) {
              &::before {
                content: '③';
              }
            }
            &:nth-of-type(4) {
              &::before {
                content: '④';
              }
            }
            &:nth-of-type(5) {
              &::before {
                content: '⑤';
              }
            }
            &:nth-of-type(6) {
              &::before {
                content: '⑥';
              }
            }
            &:nth-of-type(7) {
              &::before {
                content: '⑦';
              }
            }
            &:nth-of-type(8) {
              &::before {
                content: '⑧';
              }
            }
            &:nth-of-type(9) {
              &::before {
                content: '⑨';
              }
            }
            &:nth-of-type(10) {
              padding-left: 28px;
              &::before {
                content: '⑩';
              }
            }
          }
          span {
            display: block;
            line-height: 1.8;
            padding-bottom: 8px;
            position: relative;
            padding-left: 20px;
            box-sizing: border-box;
            i {
              display: block;
              line-height: 1.8;
              padding-bottom: 8px;
              position: relative;
              padding-left: 16px;
              box-sizing: border-box;
              font-style: normal;
              &::before {
                position: absolute;
                top: 0;
                left: 0;
                display: inline-block;
                content: '';
              }
              &:nth-of-type(1) {
                &::before {
                  content: '①';
                }
              }
              &:nth-of-type(2) {
                &::before {
                  content: '②';
                }
              }
            }
            span:before {
              content: none !important;
            }
            span {
              padding-left: 0 !important;
              padding-bottom: 0 !important;
            }
            &::before {
              position: absolute;
              top: 0;
              left: 0;
              display: inline-block;
              content: '';
            }
            &:nth-of-type(1) {
              &::before {
                content: '(1)';
              }
            }
            &:nth-of-type(2) {
              &::before {
                content: '(2)';
              }
            }
            &:nth-of-type(3) {
              &::before {
                content: '(3)';
              }
            }
            &:nth-of-type(4) {
              &::before {
                content: '(4)';
              }
            }
            &:nth-of-type(5) {
              &::before {
                content: '(5)';
              }
            }
            &:nth-of-type(6) {
              &::before {
                content: '(6)';
              }
            }
            &:nth-of-type(7) {
              &::before {
                content: '(7)';
              }
            }
            &:nth-of-type(8) {
              &::before {
                content: '(8)';
              }
            }
            &:nth-of-type(9) {
              &::before {
                content: '(9)';
              }
            }
            &:nth-of-type(10) {
              padding-left: 28px;
              &::before {
                content: '(10)';
              }
            }
            &:nth-of-type(11) {
              padding-left: 28px;
              &::before {
                content: '(11)';
              }
            }
            &:nth-of-type(12) {
              padding-left: 28px;
              &::before {
                content: '(12)';
              }
            }
            &:nth-of-type(13) {
              padding-left: 28px;
              &::before {
                content: '(13)';
              }
            }
            &:nth-of-type(14) {
              padding-left: 28px;
              &::before {
                content: '(14)';
              }
            }
            &:nth-of-type(15) {
              padding-left: 28px;
              &::before {
                content: '(15)';
              }
            }
            &:nth-of-type(16) {
              padding-left: 28px;
              &::before {
                content: '(16)';
              }
            }
            &:nth-of-type(17) {
              padding-left: 28px;
              &::before {
                content: '(17)';
              }
            }
            &:nth-of-type(18) {
              padding-left: 28px;
              &::before {
                content: '(18)';
              }
            }
            &:nth-of-type(19) {
              padding-left: 28px;
              &::before {
                content: '(19)';
              }
            }
            &:nth-of-type(20) {
              padding-left: 28px;
              &::before {
                content: '(20)';
              }
            }
          }
          &::before {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            content: '';
          }
          &:nth-of-type(1) {
            &::before {
              content: '1. ';
            }
          }
          &:nth-of-type(2) {
            &::before {
              content: '2. ';
            }
          }
          &:nth-of-type(3) {
            &::before {
              content: '3. ';
            }
          }
          &:nth-of-type(4) {
            &::before {
              content: '4. ';
            }
          }
          &:nth-of-type(5) {
            &::before {
              content: '5. ';
            }
          }
          &:nth-of-type(6) {
            &::before {
              content: '6. ';
            }
          }
          &:nth-of-type(7) {
            &::before {
              content: '7. ';
            }
          }
          &:nth-of-type(8) {
            &::before {
              content: '8. ';
            }
          }
          &:nth-of-type(9) {
            &::before {
              content: '9. ';
            }
          }
          &:nth-of-type(10) {
            padding-left: 20px;
            &::before {
              content: '10. ';
            }
          }
        }
      }
      .box {
        padding-bottom: 24px;
        h2,
        h3,
        h4 {
          padding-bottom: 16px;
        }
        p,
        ul {
          line-height: 1.8;
          padding-bottom: 8px;
        }
      }
    }
  }
}

.guide_box1 .top_content_nth02_inner .guide_inter section a {
  color: #35478c;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

.guide_box1:nth-of-type(1n + 2) {
  padding-top: 10rem;

  @media (max-width: 1024px) {
    padding-top: 2.5rem;
  }
}

.guide_infomation1 {
  box-sizing: border-box;
  font-size: 1rem;
  background-color: #EDEDED;
  padding: 1.5rem;
}

.lead {
  a {
    color: #4e7ac7;
  }
}

$font-sizes: (
  'x-small': 10px,
  'small': 13px,
  'regular': 16px,
  'large': 24px,
  'x-large': 36px,
  'huge': 50px,
);

@each $name, $size in $font-sizes {
  .has-#{$name}-font-size {
    font-size: $size !important;
  }
}

/* 動画を囲うdiv */
.video {
  position: relative;
  width: 100%; /* 横幅は100%で固定 */
  height: 0; /* 高さは0にしておく(padding-topで高さを指定するため) */
  padding-top: 56.25%; /* 高さを指定(16:9) */
}

/* YouTube埋め込み用のiframe */
.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.column_archive {
  .sec_inner_w620 {
    width: 620px;
    margin-right: 0;

    @media (max-width: 1024px) {
      width: 100%;
      margin-right: 0;
    }

    @media (max-width: 1149px) and (min-width: 1024px) {
      width: 100%;
    }
  }
}

@media (max-width: 1400px) and (min-width: 1024px) {
  .column_archive_list {
    width: 80%;
    margin: 0 auto;

    .simple {
      margin-left: 9%;
    }

    .column_w280 {
      width: 45%;
    }

    .simple:first-child {
      margin-left: 0;
    }

    .simple:nth-of-type(1n + 3) {
      margin-top: 40px;
    }

    .simple:nth-of-type(odd) {
      margin-left: 0;
    }
  }

  .column_relation_list {
    width: 100%;
    margin: 0 auto;
  }

  .detail_wrap {
    width: 90%;
    margin: 0 auto;
  }
}

@media (max-width: 1024px) {
  .column_archive_list {
    .simple:nth-of-type(1n + 2) {
      margin-top: 40px;
    }
  }
}

@media (min-width: 1400px) {
  .column_archive_list {
    width: 57.5rem;
    margin: 0 auto;

    .simple {
      margin-left: 4.2%;
    }

    .simple:first-child {
      margin-left: 0;
    }

    .simple:nth-of-type(1n + 4) {
      margin-top: 40px;
    }
    .simple:nth-of-type(3n + 1) {
      margin-left: 0;
    }
  }

  .column_relation_list {
    width: 57.5rem;
    margin: 0 auto;
  }

  .detail_wrap {
    width: 57.5rem;
  }
}

.intro {
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100%;
    height: 0;
  }

  &:before {
    top: 0;
    padding-top: 32.5%;

    @media (max-width: 1024px) {
      padding-top: 69%;
    }
  }
}

.intro_controller {
  background-size: cover;
  padding: 0.5rem 0;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 1024px) {
    padding: 1rem 0;
  }
}

.arrow_column:before {
  right: auto;
  left: -1rem;
  transform: translateY(-20%) rotate(315deg);
}

.isOpen {
  .arrow_column:before {
    transform: translateY(-60%) rotate(495deg);
  }
}

.bg-tag {
  background-color: rgba(173, 213, 247, 0.3);
}

@media (min-width: 1024px) {
  .swiper {
    margin: 0 auto;
  }
  .swiper-wrapper {
    height: 100%;
  }
  .swiper-wrapper .swiper-slide {
    width: 450px !important;
  }
}

.line_clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line_clamp_2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.img_contact_column_sp {
  @media (max-width: 1024px) {
    position: absolute;
    top: 6.5rem;
    right: 1rem;
  }
}

.arrow_back_to_top {
  &:before {
    right: auto;
    left: -1.5rem;
    transform: translateY(-10%) rotate(-45deg);
    border-top: 2px solid #757575;
    border-right: 2px solid #757575;
    width: 0.6rem;
    height: 0.6rem;
  }
}

/* 課題・導入・効果ブロック用のスタイル */
:root {
  --circle-size: clamp(5rem, 5vw, 5rem);
  --spacing: clamp(0rem, 2vw, 0rem);
}

.components-stepper__box {
  padding: 30px 25px;
  background: #fff;
  border-radius: 8px 8px 0px 0px;
}

.block-create-block-benefit-contents-block-child {
  padding-bottom: 4rem;
  position: relative;
  z-index: 1;
  &:last-child {
    padding-bottom: 0;
  }
  &:after {
    z-index: -1;
  }
  &:not(:last-child):after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(2.5rem);
    width: 2px;
    height: 100%;
    background-color: #e0e0e0;
    z-index: -1;
  }
}

.components-stepper {
  padding-left: 0 !important;
}

.components-stepper__item {
  position: relative;
  display: flex;
  gap: 1rem;

  &-label--issue:before,
  &-label--issue:after {
    background-color: rgba(127, 178, 240, 1);
  }

  &-label--introduction:before,
  &-label--introduction:after {
    background-color: rgba(78, 122, 199, 1);
  }

  &-label--effect:before,
  &-label--effect:after {
    background-color: rgba(53, 71, 140, 1);
  }

  &:before {
    content: '';
    flex: 0 0 var(--circle-size);
    height: var(--circle-size);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: #fff;
    z-index: 1;
  }
  > div {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
  }
  h2,
  h3,
  p,
  ul {
    margin-top: -2rem !important;
  }
  p,
  ul {
    font-size: 0.875rem !important;
    font-weight: bold !important;
  }
}

.components-stepper__item-label {
  position: absolute;
  top: 25px;
  left: 25px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

.components-stepper__item-label--issue:before,
.components-stepper__item-label--introduction:before,
.components-stepper__item-label--effect:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 80px;
}

.components-stepper__item-label--issue:before {
  content: '課題';
}

.components-stepper__item-label--introduction:before {
  content: '導入';
}

.components-stepper__item-label--effect:before {
  content: '効果';
}

/* 活用のポイントブロック用のスタイル */
.components-practical-point__box {
  padding: 40px 25px;
  background: rgba(246, 244, 239, 1);
  border-radius: 0px 0px 8px 8px;
  overflow: hidden;
  h2,
  p,
  ul {
    margin-top: 0 !important;
  }
  li:not(:last-child) {
    margin-bottom: 10px;
  }
}

/* 活用のポイントブロック用のスタイル */
.components-practical-point__title {
  margin-top: 0 !important;
  margin-bottom: 20px !important;
  padding-left: 26px !important;
  line-height: 1.7;
  background-image: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20id%3D%22%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2036%2036%22%20style%3D%22enable-background%3Anew%200%200%2036%2036%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bopacity%3A0.5%3B%7D%20.st1%7Bfill-rule%3Aevenodd%3Bclip-rule%3Aevenodd%3Bfill%3A%234E7AC7%3B%7D%3C%2Fstyle%3E%3Cg%20class%3D%22st0%22%3E%20%3Cpath%20class%3D%22st1%22%20d%3D%22M18%2C8C12.5%2C8%2C8%2C12.5%2C8%2C18c0%2C5.5%2C4.5%2C10%2C10%2C10s10-4.5%2C10-10C28%2C12.5%2C23.5%2C8%2C18%2C8z%20M5.5%2C18%20c0-6.9%2C5.6-12.5%2C12.5-12.5S30.5%2C11.1%2C30.5%2C18c0%2C6.9-5.6%2C12.5-12.5%2C12.5S5.5%2C24.9%2C5.5%2C18z%22%2F%3E%20%3Cpath%20class%3D%22st1%22%20d%3D%22M18%2C16.8c0.7%2C0%2C1.2%2C0.5%2C1.2%2C1.2v4.5c0%2C0.7-0.5%2C1.2-1.2%2C1.2c-0.7%2C0-1.2-0.5-1.2-1.2V18%20C16.8%2C17.3%2C17.4%2C16.8%2C18%2C16.8z%22%2F%3E%20%3Cpath%20class%3D%22st1%22%20d%3D%22M16.8%2C13.5c0-0.7%2C0.5-1.2%2C1.2-1.2h0c0.7%2C0%2C1.2%2C0.5%2C1.2%2C1.2s-0.5%2C1.2-1.2%2C1.2h0C17.3%2C14.7%2C16.8%2C14.2%2C16.8%2C13.5z%20%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  border: none;
}

/* トップページ「課題解決コンテンツ」の文章スタイル指定 */
.topPage {
  .block-create-block-cross-content-block {
    & + .block-create-block-cross-content-block {
      & + .block-create-block-cross-content-block {
        padding-bottom: 10rem;
        position: relative;
        z-index: 1;
        @media (max-width: 1024px) {
          padding-bottom: 2.5rem;
        }
      }
    }
  }
}

/* 代理店募集ページ(カバー部分画像表示） */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .tablet-image {
    margin: auto;
    display: block;
    width: 60%;
  }
}

/* 代理店募集ページ(マージン部分打ち消し（メリット・サポート） */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .no-top-margin {
    margin-top: 0 !important;
  }
}

/* 代理店募集ページ(ステップ） */

.partner-step {
  display: flex;
}

.partner-step__item {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
  &:not(:last-child) {
    &:after {
      background-color: #4e7ac7;
      width: calc(100% - 72px - 12px);
      height: 1px;
      content: '';
      position: absolute;
      top: 72px;
      left: 154px + 12px;
      order: -1;
    }
  }
}

.partner-step__icon {
  width: 145px;
  height: 145px;
  border-radius: 50%;
  margin: 0 auto 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.partner-step__item_sp {
  --circle-size: clamp(1.5rem, 3vw, 3rem);
  --spacing: clamp(0rem, 0vw, 0rem);

  padding-bottom: 4rem;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  &:not(:last-child) {
    &:after {
      background-color: #4e7ac7;
      width: 1px;
      content: '';
      position: absolute;
      left: 28px;
      top: calc(var(--circle-size));
      bottom: var(--spacing);
      z-index: -1;
      transform: translateX(calc(var(--circle-size) / 2));
      @media (min-width: 768px) {
        left: 22px;
      }
    }
  }
  &:last-child {
    padding-bottom: 0;
  }
  &:first-child .partner-step__content_sp {
    border-top: 1px dashed #4e7ac7;
  }
}

.partner-step__icon_sp {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  img {
    border-radius: 50%;
    max-width: 100%;
    max-height: 100%;
  }
}

.partner-step__content_sp {
  padding: 1rem 0;
  border-bottom: 1px dashed #4e7ac7;
  width: 70%;
  text-align: left;
  @media (min-width: 768px) {
    width: 100%;
  }
}

/* 代理店募集ページ(アコーディオン） */

.Accordion {
  &:has(.Accordion-header input:checked) {
    .Accordion-content {
      grid-template-rows: 1fr;
    }
    .Accordion-header::after {
      content: '−';
      line-height: 0.8;
    }
  }
}

.Accordion-header {
  padding: 1rem;
  display: flex;
  align-items: center;
  position: relative;
}

.Accordion-content {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 150ms ease-in-out;
}

.Accordion-content__inner {
  overflow: hidden;
}

.Accordion-header::after {
  background-color: #35478c;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  transition: transform 0.4s;
  content: '+';
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 2em;
  font-weight: bold;
  line-height: 1;
}

@media (max-width: 768px) {
  .Accordion-header::after {
    width: 2em;
    height: 1.7em;
    font-size: 1.8em;
  }
}

//（各ソリューションページ用）D1-LAWGUE表示用ボタン
.base_button_d1link {
  width: 118px;
  box-sizing: border-box;
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(53 71 140 / var(--tw-border-opacity));
  border-style: solid;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 700;
  a {
    padding: 0.5rem;
    display: block;
    &::after {
      padding: 0 0 0 0.05rem;
      vertical-align: middle;
      content: url('/images/img_icon_externallink-blue.svg');
    }
  }
}

//（利用規約、プライバシーポリシー）マークダウンコンテンツリスト用
.p-markdown-content h2:first-of-type {
  margin-top: 0 !important;
}
.p-markdown-content p {
  line-height: 1.8 !important;
}

.p-markdown-content p + p {
  margin-top: 24px;
}

.p-markdown-content ol {
  counter-reset: cnt;
}

.p-markdown-content ol ol li {
  position: relative;
  padding-left: 20px;
}

.p-markdown-content ol ol li::before {
  position: absolute;
  left: 0;
  top: 0;
  counter-increment: cnt;
  content: '(' counter(cnt) ') ';
  min-width: 36px;
  text-align: right;
}

.font-robot {
  font-family: var(--font-roboto), 'Roboto', sans-serif;
}

.font-noto {
  font-family: var(--font-noto-sans-jp), sans-serif;
}
