.has-text-align-left {
  text-align: left;
}

.has-text-align-center {
  text-align: center;
}

.has-text-align-right {
  text-align: right;
}

.has-large-font-size {
  font-size: 2.25rem;
}

.alignfull {
  width: 100vw;
  position: relative;
  right: 50%;
  left: 50%;
  max-width: 100vw;
  margin-right: -50vw;
  margin-left: -50vw;
}

/*お知らせ、導入事例、コラム共通*/
.c-article p,
.c-article ol,
.c-article ul {
  font-size: 1rem;
  margin-top: 2.5rem;
  line-height: 1.7;
}

.c-article h2 {
  font-size: 1.125rem;
  font-weight: 700;
  margin-top: 1.5rem;
  line-height: normal;

  @media (min-width: 1024px) {
    font-size: 1.5rem;
    margin-top: 2.5rem;
  }
}

.c-article h3 {
  font-size: 1rem;
  line-height: 1.7;
  font-weight: 700;
  margin-top: 2.5rem;
  border-left: 5px solid #f6f4ef;
  padding-left: 0.625rem;

  @media (min-width: 1024px) {
    font-size: 1.125rem;
    line-height: normal;
  }
}

.c-article h2 + h3 {
  margin-top: 1rem;

  @media (min-width: 1024px) {
    margin-top: 2.5rem;
  }
}

.c-article h3 + p {
  margin-top: 1rem;
}

.c-article a:not([class]) {
  color: #4e7ac7;
}

.c-article a:not([class]):hover {
  text-decoration: underline;
}

.c-article ol {
  list-style-type: decimal;
  padding-left: 1.25rem;
}

.c-article ul {
  list-style-type: disc;
  padding-left: 1.25rem;
}

/*目次*/
.lwptoc {
  border: 1px solid #f6f4ef;
  padding: 1.25rem;
  font-size: 1rem;
  line-height: 2;
  margin: 1.5rem 0;
  border-radius: 8px;

  @media (min-width: 1024px) {
    padding: 2.5rem;
    margin: 2.5rem 0;
  }
}

.lwptoc_header {
  font-size: 1.125rem;
  line-height: normal;
  text-align: center;
  margin-bottom: 1.5rem;
}

.lwptoc_toggle {
  display: none;
}

.lwptoc_item a {
  display: flex;
  color: #4e7ac7;
}

.lwptoc_item_label {
  flex: 1;
}

.lwptoc_item_number::after {
  content: '.';
  margin-right: 4px;
}
